<template>
  <div class="mt-down-header">
    <!-- breadcrumb -->
    <b-breadcrumb :items="items" class="my-bread">位置：</b-breadcrumb>
    <!-- breadcrumb end -->

    <div class="main-layout">
      <!-- list banner -->
      <div class="list-banner">
        <img src="@/assets/img/list-0@2x.png" alt>
        <div class="content flex-center-column">
          <h3>想要一份量身定制的策略吗?</h3>
          <p>那么你还在等什么?点击下方的按钮立即定制吧</p>
        </div>
      </div>
      <!-- list banner end-->

      <!-- form step2 -->
      <div class="c-border mt-30 mb-30 user-info">
        <h4 class="mb-30">填写用户信息</h4>
        <b-form class="col-6" @submit="onSubmit" @reset="onReset">
          <b-form-group
            id="input-group-1"
            label="邮箱:"
            label-for="input-1"
            description="我们不会泄露您的邮箱"
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              required
              placeholder="输入邮件"
            />
          </b-form-group>

          <b-form-group id="input-group-2" label="姓名:" label-for="input-2">
            <b-form-input id="input-2" v-model="form.name" required placeholder="输入姓名" />
          </b-form-group>
        </b-form>

        <div class="flex-common mt-60">
          <b-button variant="outline-primary" class="mr-30" @click="submitForm">上一步</b-button>
          <b-button variant="outline-primary" @click="submitForm">提交</b-button>
        </div>
      </div>
      <!-- form step2 end -->
    </div>
  </div>
</template>

<script>
import '../../assets/style/common.scss'

export default {
  data() {
    return {
      items: [
        {
          text: '主页',
          href: '/#home'
        },
        {
          text: '用户信息',
          active: true
        }
      ],

      form: {
        email: '',
        name: '',
        food: null,
        checked: []
      }
    }
  }
}
</script>

<style lang="scss">
.list-banner {
  // padding: 20px;
  font-family: PingFang-SC-Regular;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  background-size: 100% 100%;
  position: relative;
  letter-spacing: 2px;

  img {
    width: 100%;
    height: 100%;
  }
  h3 {
    font-size: 36px;
  }
  p {
    font-size: 24px;
  }

  div.content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.main-layout {
  padding: 0 105px;
}

.user-info {
  padding: 30px;
}
</style>
